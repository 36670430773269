import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import * as dotenv from 'dotenv'
dotenv.config()

export default function GetSolidHolderValid(props){

    const queryClient = new QueryClient()

    //NFT
    const contract = "0x73c78250edb666eFbC15792B4d73FC34685f85Cb"; //Solid Contract
    const id = props.IdSolid;
    const holder = props.nftHolder;

    //React query
    return (
        <QueryClientProvider client={queryClient}>
            <Nft contract={contract} id={id} holder={holder}/>
        </QueryClientProvider>
    )
    
    function Nft(props) {

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const { isLoading, error, data } = useQuery({
            queryKey: ['holdernft'],
            queryFn: () =>
            fetch(`https://polygon-mainnet.g.alchemy.com/nft/v2/${process.env.apikeyalchemy}/getOwnersForToken?contractAddress=${props.contract}&tokenId=${props.id}`
                , options
            ).then((res) => res.json(),),
        })

        if (isLoading) {
            return (
                <CircularProgress isIndeterminate color='gray.900' thickness='2px' size="24px"/>
            )
        }
        if (error) {
            return (
                <div className="trueholdersvg-not-validate">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                    </svg>
                </div>
            )
        }

        if(data){
            if (data.owners[0].toLowerCase() == props.holder.toLowerCase()){
                return (
                    <div className="trueholdersvg-validate">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            }
            else{
                return(
                    <div className="trueholdersvg-not-validate">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            }
        }
    }
}

