import { Divider } from "@chakra-ui/react"

export default function Story2(){
    return(
        <div className="screen-2 littlescreen">
            <h1 className='neue-machina'>Mint</h1>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0'/>
            <p className='poppins'>
              You’re now about to mint the NFT that 
              will be your print certificate.
            </p>
            <p className='poppins'>
              Hold it safely in your wallet as it 
              attests your artwork's authenticity.
            </p>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0' paddingTop={4}/>
            <p className='space-mono special'>
              It <span className="letterspace">certifies</span> your NFT ownership but also 
              validates the authenticity of your <span className="italic">Solid NFT</span>.
            </p>
        </div>
    )
}