import React from "react"

import { Divider } from "@chakra-ui/react"
import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
export default function UndefinedPage(){
    return(
        <div className="undefined-screen">
            <h1 className='neue-machina'>Error</h1>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0'/>
            <p className='poppins'>
              Your command ID seems odd and is not reconize by our systems
            </p>
            <p className='poppins'>
              Contact us and we will find a solutions for you !
            </p>
            <div className="link poppins">
                <Link href='https://www.solidnft.com/contact' isExternal>
                    Contact us <ExternalLinkIcon mx='2px' />
                </Link>
            </div>
        </div>
    )
}