import React, { useState } from 'react';
import { CircularProgress } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { CircularProgress } from '@chakra-ui/react'
import { motion } from "framer-motion"

     
import Story1 from './Story1';
import Story2 from './Story2';  
import Story3 from './Story3';
import DetailsPage from '../DetailsPage';
import ValidationAnimation from '../../utils/ValidationAnimation';

import * as dotenv from 'dotenv'
dotenv.config()

export default function StaticStories(props) {
  //Gestion Page et story
  const [currentScreen, setCurrentScreen] = useState(1);
  const [percentage, setPercentage] = useState(25);
  const [certifScreen, setCertifScreen] = useState(false)
  const [activateBtn, SetActivateBtn] = useState(false);

  function goToNextScreen() {
    if(currentScreen == 3){
        SetActivateBtn(true);
    }
    if (currentScreen > 3){
      return true;
    }
    setCurrentScreen(currentScreen + 1);
    setPercentage(percentage + 25);
    console.log(currentScreen)
  }

  function SeeCertificate(){
    setCertifScreen(true);
  }

  if(certifScreen===false){
    return(
        <motion.div
              className="story-container"
              initial={{ opacity: 0 }}
              transition={{ duration: 1}}
              animate={{ opacity: 1 }}
        >
          <div className="screen" style={{ transform: `translateX(-${(currentScreen - 1) * 100}vw)` }}>
            <Story1 />
            <Story2 />
            <Story3 />
            <div className="buttons-staticscreen">
              <ValidationAnimation/>
              <button className='space-mono button-staticstories letterspace' onClick={SeeCertificate}>See your certificate</button>
            </div>
          </div>
          <div className={`buttons`}>
            <>
              <div className="button-container">
                <span className={currentScreen === 1 ? "active" : ""}></span>
                <span className={currentScreen === 2 ? "active" : ""}></span>
                <span className={currentScreen === 3 ? "active" : ""}></span>
              </div>
              <div className='button'>
                <CircularProgress value={percentage} size='80px' thickness='1px' color='black'/>
                <ChevronRightIcon onClick={goToNextScreen} className="icon" color="white" boxSize={12} />
              </div>
            </>
          </div>  
        </motion.div>
    )
  }
  else if (certifScreen===true){
    return <DetailsPage certificate={props.certificate} certificateId={props.certificateId} />
  }
  

} 
