import React, { useState } from 'react';
import { CircularProgress } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Divider, CircularProgress } from '@chakra-ui/react'
import { motion } from "framer-motion"
import SliderText from '../../utils/SliderText'; 
import Story1 from './Story1';
import Story2 from './Story2';
import Story3 from './Story3';
import * as dotenv from 'dotenv'
import ValidationAnimation from '../../utils/ValidationAnimation';
dotenv.config()

export default function Stories(props) {
  //Gestion Page et story
  const [currentScreen, setCurrentScreen] = useState(1);
  const [percentage, setPercentage] = useState(25);
  const [mintScreen, setMintScreenV] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [activateReturn, setActivateReturn] = useState(false);

  function goToNextScreen() {
    setCurrentScreen(currentScreen + 1);
    setPercentage(percentage + 25);
  
    if (percentage === 75) {
      setMintScreen(1);
    }
  }
  function goToLastScreen() {
    if (currentScreen > 1) {
      setCurrentScreen(currentScreen - 1);
      setPercentage(percentage - 25);
    }
  }
  async function GotoDetailsPage(){
    const requestBody = {
      certificateId: certificateId,
      Production: Production,
    };
    const formData = new FormData();
    for (const key in requestBody) {
        formData.append(key, requestBody[key]);
    }
    const rep = await fetch('https://solid-certificate-v1.oa.r.appspot.com/certificate/firstxp', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    body: new URLSearchParams(formData)
    })
    .then(response => {
        console.log(`First experience completed`);
        return response.body;
    })
  }
  //Function setMintScreen
  function setMintScreen(){
    setMintScreenV(true);
  }

  function renderEthAddress(address) {
    if (address) {
      return address.substring(0, 5) + '...' + address.substring(address.length - 3);
    } else {
      return 'N/A';
    }
  }
  //Certificate info 
  const certificate = props.certificate; 
  const certificateId = props.certificateId;
  const Production = props.certificate.data.Production;
  // Tx Request State
  const [txState, setTxState] = useState("not sended");
  //Tx Data 
  const [txData, setTxData] = useState("null");
  // Address of the wallet you want to mint the NFT to
  const walletAddress = certificate.data.nftOwner;
  // Metadata of the solid
  const metaData = certificate.data.jsonSolidCertificate;
  
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  async function startClaimingProcess(apiKey) {
    const requestBody = {
        certificateId: certificateId,
        walletAddress: walletAddress,
        ipfs: `ipfs://${metaData}`,
        Production: Production,
    };
    const formData = new FormData();
    for (const key in requestBody) {
        formData.append(key, requestBody[key]);
    }
    const rep = fetch('https://solid-certificate-v1.oa.r.appspot.com/certificate/claim', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Key': apiKey,
        },
    body: new URLSearchParams(formData)
    })
    .then(response => {
        console.log(`Claim started and finished, claim status changed ${response.statusCode}`);
        console.log(response.body);
        return response.body;
    })
    .catch(error => console.error(error));
    return rep
  }

  async function StartMint(){
    setIsAnimated(true);
    console.log(txState)
    const nft = await startClaimingProcess();
    setTxData(nft);
    await sleep(15000);
    setTxState("success")
    console.log(txState)
  }
  return(
    <motion.div
          className="story-container"
          initial={{ opacity: 0 }}
          transition={{ duration: 1}}
          animate={{ opacity: 1 }}
    >
      <div className="screen" style={{ transform: `translateX(-${(currentScreen - 1) * 100}vw)` }}>
        <Story1 className="littlescreen"/>
        <Story2 className="littlescreen"/>
        <Story3 className="littlescreen"/>
        <div className={`screen-4 littlescreen ${mintScreen ? "h100" : ""}`}>
            <h1 className='neue-machina'>Mint</h1>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0'/>
            <div className='mint-animation'>
              {
                (() =>{
                  if (isAnimated=== true){
                    return(
                      <div className='mintinprogress'>
                        {
                          txState=="success" ? (
                            <motion.div
                              initial={{ opacity: 0 }}
                              transition={{ duration: 1}}
                              animate={{ opacity: 1 }}
                              className="successmodules"
                            >
                              <ValidationAnimation/>
                              <motion.div
                                className='div-delay-success'
                                transition={{ delay: 1.5 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                              >
                                <button className='space-mono detailsbutton' onClick={GotoDetailsPage}>See my <span className="letterspace">certificate</span></button>
                              </motion.div>
                            </motion.div>
                          ) : (
                            <>
                              <CircularProgress className='circular-loading' isIndeterminate color='blue.900' size='100px' thickness='2px'/>
                              <SliderText address={renderEthAddress(certificate.data.nftOwner)}/>
                            </>
                          ) 
                        }
                      </div>
                    )
                  }
                  else{
                    return(
                      <div className="buttons-mintscreen">
                        <button className='space-mono button-mintscreen' onClick={StartMint}>Mint your <span className="letterspace">certificate</span></button>
                      </div>
                    )
                  }
                })()
              }
            </div>
        </div>
      </div>
      <div className={`buttons ${mintScreen ? "displaynone" : ""}`}>
        {!mintScreen && (
          <>
            <div className="button-container">
              <span className={currentScreen === 1 ? "active" : ""}></span>
              <span className={currentScreen === 2 ? "active" : ""}></span>
              <span className={currentScreen === 3 ? "active" : ""}></span>
              <span className={currentScreen === 4 ? "active" : ""}></span>
            </div>
            <div className='container-controller-stories'>
              <svg onClick={goToLastScreen} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" 
                className={`arrow-first-stories ${currentScreen === 1 ? " disabled" : ""}`}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>
              <div className='button'>
                <CircularProgress value={percentage} size='80px' thickness='1px' color='black'/>
                <ChevronRightIcon onClick={goToNextScreen} className="icon" color="white" boxSize={12} />
              </div>
            </div>
            
          </>
        )}
        {mintScreen && (
          <div className={`buttons-mintscreen ${mintScreen ? "" : "displaynone"}`}>
            <button className='space-mono button-mintscreen' onClick={StartMint}>Mint your <span className="letterspace">certificate</span></button>
          </div> 
        )}
      </div>  
    </motion.div>
  )

} 
