import React from 'react';
import { useState, useEffect } from 'react';
import { ref } from "firebase/database";
import { database } from '../utils/Firebase';
import { useDatabaseValue, useDatabaseSnapshot } from "@react-query-firebase/database";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import '../../src/App.css'
import DetailsPage from './DetailsPage';
import UndefinedPage from './UndefinedPage';
import Stories from './Stories/Stories';
import LoadingPage from './LoadingPage';


export default function ControlPage(){
  const queryClient = new QueryClient()
  //Get id of the page url
  const searchParams = new URLSearchParams(document.location.search);
  const certificateId = searchParams.get("id");
  return(
    <QueryClientProvider client={queryClient}>
      <Certificate certificateId={certificateId}/>
    </QueryClientProvider>
  )
}

function Certificate(props) {
  const certificateId = props.certificateId;
  const dbRef = ref(database, `certificate/production/${certificateId}`);
  const certificate = useDatabaseValue(["", certificateId], dbRef, {
    subscribe: true,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading || certificate.isLoading) {
    return (
      <LoadingPage/>
    )
  }
  else {
    if (certificate.data == null){
      return <UndefinedPage certificateId={certificateId} />
    }
    else if (certificate.data.firstxp === false) {
      return <DetailsPage certificate={certificate} certificateId={certificateId} />
    }
    else{
      return <Stories certificate={certificate} certificateId={certificateId}/>
    }
  }
}