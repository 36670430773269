import React from 'react';

import ControlPage from '../components/pages/ControlPage';

import './App.css'

export default function App() {
    return (
        <ControlPage />
    )
}