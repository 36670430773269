import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database";
import * as dotenv from 'dotenv'
dotenv.config()

const firebase = initializeApp({
  apiKey: process.env.apiKey,
  authDomain: process.env.authDomain,
  databaseURL:  process.env.databaseURL,
  projectId: process.env.projectId,
  storageBucket: process.env.storageBucket,
  messagingSenderId: process.env.messagingSenderId,
  appId: process.env.appId,
  measurementId: process.env.measurementId
});

export const database = getDatabase(firebase);