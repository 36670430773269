import React from 'react';
import { useState } from 'react';
import { motion } from "framer-motion"
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import * as dotenv from 'dotenv'
import StaticStories from './Stories/StaticStories';
import GetNftHolderValid from '../utils/GetTrueHolder';
import GetSolidHolderValid from '../utils/GetTrueSolidHolder';
import img from '../../src/assets/img/opensea-logo.png'
dotenv.config()

const queryClient = new QueryClient()

function Imgnft(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Example contract={props.contract} id={props.id}/>
    </QueryClientProvider>
  )
}

function Example(props) {
  const options = {
    method: 'GET',
    headers: {
      'X-API-KEY': process.env.apikeyopensea
    }
  };
  const { isLoading, error, data } = useQuery({
    queryKey: ['imgUrl'],
    queryFn: () =>
      fetch(`https://api.opensea.io/api/v1/asset/${props.contract}/${props.id}/?include_orders=false`, options).then(
        (res) => res.json(),
      ),
  })
  console.log(data)
  if (isLoading || data.isLoading || data==="undefined") return 'Loading...'

  if (error) return 'An error has occurred: ' + error.message

  return <img src={data.image_url} alt="NFT Image" />
}

export default function DetailsPage(props){
    
    //Front menu
    const [menu1Visible, setMenu1Visible] = useState(true);
    const [menu2Visible, setMenu2Visible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState("null");
    const [storiesScreen, SetStoriesScreen] = useState(false);

    function renderEthAddress(address) {
      if (address) {
        return address.substring(0, 5) + '...' + address.substring(address.length - 3);
      } else {
        return 'N/A';
      }
    }

    function goStories(){
      SetStoriesScreen(true)
    }
    console.log(props)
    if(storiesScreen == false) {
      return ( 
        <div className="certificate">
            {menu1Visible && (
              <motion.div 
                className="certificate-img"
                initial={{ opacity: 0 }}
                transition={{ duration: 1}}
                animate={{ opacity: 1 }}
                drag
                dragElastic={0.07}
                dragConstraints={{ top: -1, left: -1, right: 1, bottom: 1}} 
              >
                <Imgnft contract={props.certificate.data.nftContract} id={props.certificate.data.nftId} />
              </motion.div>
            )}
            {menu2Visible && (
              <motion.div
                className="certificate-img"
                initial={{ opacity: 0 }}
                transition={{ duration: 1}}
                animate={{ opacity: 1 }}
                drag
                dragElastic={0.07}
                dragConstraints={{ top: -1, left: -1, right: 1, bottom: 1}} 
              >
                <img id="img-certificate" alt='Solid Certificate' src={props.certificate.data.urlGcloud} />
              </motion.div>
            )}
          <div className="info-solid">
            <div className="certificate-menu">
              <button 
                style={{ 
                  color: menu1Visible ? "#000" : "#9D9D9D",
                  border: `2px solid ${menu1Visible ? "#D2D2D2" : "#fff"}`
                }}
                onClick={() => {setMenu1Visible(true); setMenu2Visible(false);}}
                className='poppins menu-sizetext'
              >
                NFT
              </button>
              <button 
                style={{ 
                  color: menu2Visible ? "#000" : "#9D9D9D",
                  border: `2px solid ${menu2Visible ? "#D2D2D2" : "#fff"}`
                }}  

                onClick={() => {setMenu1Visible(false); setMenu2Visible(true);}}
                className='poppins menu-sizetext'
              >
                Solid NFT
              </button>
            </div>
  
            {menu1Visible && (
              <motion.div 
              className='middlemenu'
              initial={{ opacity: 0 }}
              transition={{ duration: 1}}
              animate={{ opacity: 1 }}
              >
                <motion.div 
                  className="nft"
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1}}
                  animate={{ opacity: 1 }}
                >
                  <div className="certificate-owner">
                    <h3 className='poppins'>Owner</h3>
                    <p className='space-mono'>{renderEthAddress(props.certificate.data.nftOwner)}</p>
                    <GetNftHolderValid 
                      nftContract={props.certificate.data.nftContract} 
                      nftId={props.certificate.data.nftId} 
                      nftHolder={props.certificate.data.nftOwner}
                    />
                  </div>
                  <div className="active-menu-nft">
                    <h3 className='poppins'>NFT</h3>
                    <div className='active-menu-nft-data'>
                      <div className="border poppins" id='adress' >Contract ‎  <span className='space-mono'>{renderEthAddress(props.certificate.data.nftContract)}</span></div>
                      <div className="border poppins" id='id'>ID ‎ <span className='space-mono'>{props.certificate.data.nftId}</span></div>
                    </div>
                  </div>
                </motion.div>
                <div className="active-link-solid">
                  <a href={`https://opensea.io/assets/ethereum/${props.certificate.data.nftContract}/${props.certificate.data.nftId}`} target="_blank">
                    <div className="logo-opensea">
                      <img src={img} alt='logo opensea'/>
                    </div>
                  </a>
                </div>
              </motion.div>
            )}
            {menu2Visible && (
              <motion.div 
                className='middlemenu'
                initial={{ opacity: 0 }}
                transition={{ duration: 1}}
                animate={{ opacity: 1 }}
              >
                <motion.div 
                  className="nft"
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1}}
                  animate={{ opacity: 1 }}
                >
                  <div className="certificate-owner">
                    <h3 className='poppins'>Owner</h3>
                    <p className='space-mono'>{renderEthAddress(props.certificate.data.nftOwner)}</p>
                    <GetSolidHolderValid 
                      nftContract={props.certificate.data.nftContract} 
                      IdSolid={props.certificate.data.IdSolid} 
                      nftHolder={props.certificate.data.nftOwner}
                    />
                  </div>
                  <div className="active-menu-nft">
                    <h3 className='poppins'>Print Specs</h3>
                    <div className='active-menu-nft-data'>
                      <div className="border poppins" id='adress'>Size ‎ <span className='space-mono'>{props.certificate.data.SolidDimensions}</span></div>
                      <div className="border poppins" id='id'>Framing ‎ <span className='space-mono'>{props.certificate.data.SolidFraming}</span></div>
                    </div>
                  </div>
                </motion.div>
                <div className="active-link-solid">
                  <a href={props.certificate.data.urlSolidOpensea} target="_blank">
                    <div className="logo-opensea">
                      <img src={img} alt='logo opensea'/>
                    </div>
                  </a>
                </div>
              </motion.div>
            )}
            <button className='space-mono button-see-certif' onClick={goStories}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="arrow-stories">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>
            </button>
          </div>
        </div>
      )
    }
    else if (storiesScreen==true){
      return(
          <StaticStories certificate={props.certificate} certificateId={props.certificateId}/>
      )
    }
}