import { useState, useEffect } from "react"
import { CheckIcon } from "@chakra-ui/icons";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

export default function SliderText(props){

  const Steps = [
    { text: "Checking certificate id", completed: false },
    { text: "Retrieving print order", completed: false },
    { text: "Retrieving NFT metadata", completed: false },
    { text: "Minting Certificate", completed: false },
    { text: `Transfering to ${props.address}`, completed: false },
  ];

  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  
  useEffect(() => {
    let timeoutId;
    if (currentStepIndex === 0) {
      timeoutId = setTimeout(() => {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    } else if (currentStepIndex === 1) {
      timeoutId = setTimeout(() => {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    } else if (currentStepIndex === 2) {
      timeoutId = setTimeout(() => {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    } else if (currentStepIndex === 3) {
      timeoutId = setTimeout(() => {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    } else if (currentStepIndex === 4) {
      timeoutId = setTimeout(() => {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [currentStepIndex]);
  
  return(
    <div className="slider-text space-mono">
      {Steps.slice(0, currentStepIndex + 1).map((step, index) => (
        <h3 key={index} className={`slider-text-step letterspace ${index === currentStepIndex ? "active" : ""}`}>
          <CheckIcon mr={2} />
          {step.text}
        </h3>
      ))}
    </div>
  )
}