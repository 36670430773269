import React from 'react'
import { useState, useEffect } from 'react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { motion } from "framer-motion"
export default function LoadingPage(){
    
    return(
        <motion.div
            className="loading-page"
            initial={{ opacity: 0 }}
            transition={{ duration: 1}}
            animate={{ opacity: 1 }}
        >
            <CircularProgress className='circular-loading' isIndeterminate color='blue.900' size='100px' thickness='2px'/>
            <h2 className='space-mono'>Your experience <br></br>is loading...</h2>
        </motion.div>
    )
}