import React, { useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { CheckCircleIcon } from '@chakra-ui/icons'; // assuming you're using Chakra UI for icons

export default function ValidationAnimation() {
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationFinished(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  if (animationFinished) {
    return <CheckCircleIcon boxSize={24} color="green.400" />;
  } else {
    return (
      <Player
        src='https://assets2.lottiefiles.com/packages/lf20_vuliyhde.json'
        className="player"
        autoplay
      />
    );
  }
}
