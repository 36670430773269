import { Divider } from "@chakra-ui/react"

export default function Story1(){
    return(
        <div className="screen-1 littlescreen">
            <h1 className='neue-machina'>Welcome</h1>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0'/>
            <p className='poppins'>
              We're truly grateful for your choice 
              to purchase a <span className="italic"> Solid NFT</span> !
            </p>
            <p className='poppins'>
              We're confident you'll take great pride 
              in your unique piece, crafted with numerous 
              hours of dedication and skill.
            </p>
        </div>
    )
}