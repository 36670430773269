import { Divider } from "@chakra-ui/react"

export default function Story3(){
    return(
        <div className="screen-3 littlescreen">
            <h1 className='neue-machina'>Certify</h1>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0'/>
            <p className='poppins'>
              We are pleased to present your personalized 
              Print Certificate, which meticulously details 
              all pertinent information about your artwork. 
            </p>
            <Divider orientation='horizontal' borderBottomWidth='0.3px' opacity={0.7} borderColor='#A0AEC0' paddingTop={4}/>
            <p className='space-mono special'>
              Your <span className="letterspace">certificate</span> may appear hidden in your
              Opensea profile. <br></br>
              Unhide it to share it with the world.
            </p>
        </div>
    )
}